<template>
  <v-card class="mb-12 pa-4">
    <v-row no-gutters align="center">
      <v-col cols="3">
        <h2 class="overline ma-0">現在のランク</h2>
      </v-col>
      <v-col cols="9">
        <p class="subtitle-1 font-weight-bold ma-0">{{item.rank_data.rank_title}}</p>
      </v-col>
    </v-row>

    <v-divider class="my-3"></v-divider>

    <v-row no-gutters align="center" justify="space-between">
      <v-col cols="3">
          <div style="position:relative;">
            <v-img v-if="item.rank_data.image_path" src="@/assets/stamp/rankicon@2x.png" width="70" class=""></v-img>
            <v-img v-else src="@/assets/stamp/rankicon@2x.png" width="70" class=""></v-img>
            <div class="ranknumber">
              {{ordinal(item.rank_data.rank)}}<br>Rank
            </div>
          </div>
      </v-col>
      <v-col cols="8">
        <p class="caption text-center mb-4">次のランクまであと{{item.rank_data.rank_remaining}}個</p>
        <v-btn rounded depressed class="maincolor-btn2 default_button btn_small w100" @click="open">ランクについて</v-btn>
      </v-col>
    </v-row>
  </v-card>
  
</template>

<script>
export default {
  props: ['item'],
  data() {
    return {
      rankDialog: false
    };
  },
  methods: {
    open() {
      this.$emit('openDialog');
    },
    close() {
      this.rankDialog = false;
    },
    ordinal(n) {
      var result;
      var s1 = +('' + n).slice(-1);
      var s2 = +('' + n).slice(-2);
      if (s2 >= 11 && s2 <= 13) {
        result = n + 'th';
      } else if (s1 === 1) {
        result = n + 'st';
      } else if (s1 === 2) {
        result = n + 'nd';
      } else if (s1 === 3) {
        result = n + 'rd';
      } else {
        result = n + 'th';
      }
      return result;
    }
  }
}
</script>

<style scoped>
.ranknumber {
  position: absolute;
  top:15px;
  left: 0;
  text-align: center;
  width: 70px;
  font-size: 13px;
  z-index: 10;
  line-height: 1;
  font-weight: bold;
}
</style>