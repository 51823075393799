<template>
  <v-content class="stamp">
    <div v-if="item">

    <div class="pa-3">
      <div class="text-center">
        <h1 class="subtitle-1 font-weight-black mb-5 border_maincolor nl2br" style="border-bottom: solid 1px; display: inline-block;">{{item.title}}</h1>
      </div>

      <div class="text-center">
        <v-chip outlined x-small class="mb-2" style="color:#9D9D9D; border-color: #9D9D9D;">{{item.stamp_number}}枚目</v-chip>
        <p class="fontsize11 font-weight-bold" style="color:#9D9D9D;" v-if="item.expiration_date">有効期限：{{item.expiration_date}}</p>
      </div>

      <v-divider class="mb-5"></v-divider>

      <div class="text-center mb-10">
        <div class="fontsize13 nl2br mb-3" v-html="item.headline" v-if="item.headline"></div>
      </div>

      <v-card v-for="(stampdata, index) in item.stamp_data" :key="index" class="mb-1 text-center">
        <!-- 見出し -->
        <h3 class="bg_stamp">
          来店スタンプ
          <span style="display: block; font-size: 0.6125rem;">{{ stampdata.complete_number }}個あつめるとやまや商品プレゼント！</span>
        </h3>
        <!-- スタンプ部分 -->
        <div class="text-center mb-0 px-0 pb-0">
          <v-row no-gutters class="px-4 justify-space-around align-start">
            <div class="mb-1 pa-1" v-for="stampnum in stampdata.stamps" :key="stampnum.id">
              <v-avatar :color="$root.maincolorop" size="48">
                <img v-if="stampnum.image_path" :src="stampnum.image_path">
                <img v-else src="@/assets/i_stamp_90.png">
              </v-avatar>
              <p class="fontsize9 maincolor text-center stamp-get-date">{{ stampnum.get_date }}</p>
            </div>

            <div class="mb-1 pa-1" v-for="val in empty_stamps[index]" :key="val.id">
              <v-avatar color="#F4F4F4" size="48">
                <span class="title" style="color:#DEDEDF;">{{ val }}</span>
              </v-avatar>
              <p class="fontsize9 maincolor text-center"></p>
            </div>
          </v-row>
        </div>
        <!-- 来店スタンプについてもっと知る -->
        <div class="text-center mt-2 pb-5">
          <a
            class="fontsize11 pa-2"
            style="color:#333; text-decoration:underline;"
            @click="showStampAboutDialog()"
          >※来店スタンプについてもっと知る</a>
        </div>
        <!-- 来店スタンプGET -->
        <div class="pt-2">
          <v-btn
            rounded
            depressed
            class="maincolor-bg btn_small mb-0 pb-0 fontsize13 font-weight-bold"
            style="width:12.5rem;"
            @click.stop="toStampAuth(item.id, item.auth_type, item.shop_id)"
          >
            来店スタンプGET
          </v-btn>
        </div>
        <!-- 獲得チケットを確認する -->
        <div class="py-4 font-weight-bold">
          <v-btn
            v-if="item.is_complete_ticket == 1"
            rounded
            depressed
            class="btn_small mb-0 pb-0 fontsize11 maincolor-border-btn"
            style="width:12.5rem;"
            @click.stop="routerBindTo({ name: 'stamp_bonuslist', query: { stamp_id: item.id, shop_id: item.shop_id}})"
          >
            獲得チケットを確認する
          </v-btn>
          <v-btn
            v-else
            rounded
            depressed
            disabled
            class="btn_small mb-0 pb-0 fontsize11 maincolor-border-btn-disabled"
            style="width:12.5rem; background-color:#F4F4F4 !important;"
            @click.stop="routerBindTo({ name: 'stamp_bonuslist', query: { stamp_id: item.id, shop_id: item.shop_id}})"
          >
            獲得チケットを確認する
          </v-btn>
        </div>
      </v-card>

      <!-- ランク表示 -->
      <RankView
        :item="item"
        v-if="item.rank_data"
        ref="rankView"
        @openDialog="showRankDialog"
      ></RankView>

    </div>

    <div v-if="item.memo_flg">
      <div class="memotitlebar px-3 py-2 mb-6">
        <h3 class="body-2">次回来店予定メモ</h3>
      </div>

      <v-row no-gutters class="px-3 justify-space-between align-center" v-if="item.memo_data">
        <v-col cols="3">
          <p class="fontsize11 font-weight-bold" style="color:#9D9D9D;">来店予定日</p>
        </v-col>
        <v-col cols="9">
          <p class="fontsize13">{{item.memo_data.memo_date}}</p>
        </v-col>

        <v-col cols="3">
          <p class="fontsize11 font-weight-bold" style="color:#9D9D9D;">時間</p>
        </v-col>
        <v-col cols="9">
          <p class="fontsize13">{{item.memo_data.memo_time}}</p>
        </v-col>

        <v-col cols="3" class="align-self-start">
          <p class="fontsize11 font-weight-bold" style="color:#9D9D9D;">メモ</p>
        </v-col>
        <v-col cols="9" class="align-self-start">
          <p class="fontsize13">{{item.memo_data.memo}}</p>
        </v-col>
      </v-row>

      <v-row no-gutters class="mb-10 px-3 justify-space-between align-center">
        <v-col cols="6" class="text-center">
          <v-btn v-if="item.memo_data" rounded depressed class="maincolor-btn2 default_button btn_small subtitle-2 w90" @click.stop="routerBindTo({ name: 'stamp_memolist', query: { stamp_id: item.id, shop_id: item.shop_id}})">過去のメモ一覧</v-btn>
          <v-btn v-else disabled rounded depressed class="maincolor-btn2 default_button btn_small subtitle-2 w90" style="background-color:#EFEFEF;" @click.stop="routerBindTo({ name: 'stamp_memolist', query: { stamp_id: item.id, shop_id: item.shop_id}})">過去のメモ一覧</v-btn>
        </v-col>
        <v-col cols="6" class="text-center">
          <v-btn rounded depressed class="maincolor-bg default_button btn_small subtitle-2 w90" @click.stop="routerBindTo({ name: 'memo_regist', query: { stamp_id: item.id, shop_id: item.shop_id}})">メモを編集する</v-btn>
        </v-col>
      </v-row>
    </div>

    <div class="text-center mt-10">
      <v-btn
        rounded
        depressed
        class="mb-10 maincolor-bg btn_small fontsize13 font-weight-bold"
        style="width:12.5rem"
        @click="routerBindTo({ name: 'stamp_list', params: { shop_id: init.shop_id}})"
      >スタンプ一覧に戻る</v-btn>
    </div>

    <!-- スタンプカードについてダイアログ -->
    <v-dialog v-model="aboutDialog" persistent>
      <v-card class="ticket_dialogue pa-3">
        <h4 class="subtitle-1 font-weight-bold">スタンプカードについて</h4>
        <v-divider class="my-2"></v-divider>
        <p class="caption mb-10">{{item.detail}}</p>

        <h4 class="subtitle-1 font-weight-bold">対象店舗一覧</h4>
        <v-divider class="my-2"></v-divider>
        <p class="caption mb-10">
          {{item.shop_name}}
        </p>

        <h4 class="subtitle-1 font-weight-bold">お使いのスマートフォンについて</h4>
        <v-divider class="my-2"></v-divider>
        <p class="caption mb-10">
          現在ご使用中のスマートフォンにて、機種変更ならびにアプリの再インストールをお考えのお客様へ。<br>
          同じOSへの変更の場合も異なるOSへの変更の場合も、現在取得しているポイントは引き継ぐことが可能です。
        </p>

        <div class="text-center">
          <v-btn rounded depressed class="caption font-weight-black mb-10 w80" style="background-color:#EFEFEF;" @click="aboutDialog = false">閉じる</v-btn>
        </div>
      </v-card>
    </v-dialog>

    <!-- ランクについてダイアログ -->
    <RankDialog :item="ranks" :init="init" ref="rankDialog" v-if="ranks"></RankDialog>

    <!-- スタンプについてダイアログ -->
    <StampAboutDialog :stamp="item" ref="stampAboutDialog"></StampAboutDialog>

    <!-- ランクアップダイアログ -->
    <!-- ネイティブ側で表示するため、不要
    <RankUpDialog :data="ranks" :item="item" ref="rankUpDialog"></RankUpDialog>
    -->
    </div>
  </v-content>
</template>

<script>
import RankView from "@/views/stamp/RankView.vue";
import RankDialog from "@/views/stamp/RankDialog.vue";
import StampAboutDialog from "@/views/wpoint/StampAboutDialog.vue";
// import RankUpDialog from "@/views/stamp/RankUpDialog.vue";
export default {
  components: {
    RankView,
    RankDialog,
    StampAboutDialog,
    //RankUpDialog,
  },
  data: () => ({
    isExpand: [],
    stampCompleteTextFlg: [],
    selected_shop: 'すべて',
    aboutDialog: false,
    rankDialog: false,
    init: [],
    item: [],
    ranks: null,
    num: null,
    start_card_num: [0],
    empty_stamps: [],
  }),
  watch: {
    isExpand: {
      deep:true,
      handler: function () {
        this.isExpand.forEach((item, i) => {
            this.stampCompleteTextFlg[i] = typeof item === 'undefined' ? true : false;
        });
      }
    }
  },
  async created () {
    // GET パラメータを取得
    this.getUrlParam()

    //createdで、localStorageから全ての保存情報を取得しinitに格納
    this.init = this.storageGet('*')

    var params = {
      'stamp_id': this.init.stamp_id,
      'shop_id': this.init.shop_id,
      'preview': this.getUrlGetParam('preview'),
    }
    if (this.init.selected_shop) {
      this.selected_shop = this.init.selected_shop
    }
    try{
      // スタンプ詳細API
      var res = await this.apiCall('/stamp/detail', {params: params}, 'get');
      if (!res) return false;
      this.$set(this, 'item', res.data);

      var start = 1;
      var tmpArray = null;
      if(this.item.stamp_data){
        this.item.stamp_data.forEach((value) => {
          this.start_card_num.push(value.complete_number);
          tmpArray = [];
          for (let i = start; i <= value.complete_number; i++) {
            start++;
            if (value.count < i) {
              tmpArray.push(i);
            }
          }
          this.empty_stamps.push(tmpArray);
        })
      }

      // スタンプランクAPI
      if(this.item.rank_data){
        var rank_res = await this.apiCall('/stamp/get-rank', params, 'post');
        if (!rank_res) return false;
        this.$set(this, 'ranks', rank_res);

        /* ネイティブ側でランクアップダイアログは表示するため、不要
        if (this.ranks.rankuped == 1) { //ランクアップしていた場合、ランクアップダイアログを表示
            this.$refs.rankUpDialog.open()
        }
        */
      }

    } catch(e) {
        this.callDialog('通信エラー', 'ネットワークの状態が不安定です。再度お試しください', 3);
    }
  },
  methods: {
    noStampCount: function (compnumber, stampNum) {
      return compnumber - stampNum
    },
    showRankDialog() {
      this.$refs.rankDialog.open();
    },
    showStampAboutDialog() {
      this.$refs.stampAboutDialog.open();
    },
    toStampAuth(id, auth_type, shop_id){
      this.loading = true
      var destination = null
      var screenID = null
      switch (auth_type) {
        case 1:
          destination = 'stampAuthCodeActivity'
          screenID = 'stamp_auth_code'
          break;
        case 2:
          destination = 'stampAuthQrActivity'
          screenID = 'stamp_auth_qr'
          break;
        case 3:
          destination = 'stampAuthBeaconActivity'
          screenID = 'stamp_auth_beacon'
          break;
        case 4:
          destination = 'stampAuthWifiActivity'
          screenID = 'stamp_auth_wifi'
          break;
        default:
          destination = 'stampAuthCodeActivity'
          screenID = 'stamp_auth_code'
          break;
      }
      var tonative_req = {
        'destination': destination,
        'feature_id': 'stamp',
        'screen_id': screenID,
        'transition_type': 3,
        'stamp_id': id,
        'shop_id': shop_id,
        //暫定対応
        'top_type': 1,
        'cassette_id': 1
      };

      if (!(this.screenTransition(tonative_req))) {
        this.loading = false
        this.callDialog('遷移エラー', '遷移情報が不正です。再度アクセスして下さい。', 2);
      }else{
        this.loading = false
      }
    },
    calcStamp(current, complete) {
      if (current > complete) {
          return 0;
      }

      return complete - current;
    }
  }
}
</script>

<style scoped>
.memotitlebar {
  background-color: #E4E4E4;
  color: #000;
  font-weight: bold;
}
</style>
<style>
.stampPanelIcon div {
  margin: auto !important;
}
.stamp-get-date {
  margin-bottom: 0 !important;
}
.bg_stamp {
  display: block;
  padding: 0.5rem;
  margin: -0.5rem 0rem 0.5rem 0rem;
  background: url("~@/assets/point/bg_stamp.jpg") top 39% center /cover no-repeat;
  color: #fff;
  border-radius: 10px 10px 0 0;
  font-size: 1rem;
}
.maincolor-border-btn {
    border: solid 1px var(--main_color);
    color: var(--main_color) !important;
    background-color: #fff !important;
    height: 1.5rem !important;
    width:100%;
}
.maincolor-border-btn-disabled {
    height: 1.5rem !important;
}
</style>
