<template>
  <v-dialog v-model="rankDialog" persistent>
    <v-card class="" v-if="item.rank_list">

      <p class="maincolor-bg subtitle-2 text-center pa-3">ランクごとに素敵な特典がもらえます</p>
      <div class="px-3">
      <!--
              <h4 class="subtitle-1 font-weight-bold">ランクについて</h4>
      <v-divider class="my-2"></v-divider>
              <p class="caption mb-10">
                {{item.rank_description}}
              </p>
      -->

      <h4 class="subtitle-1 font-weight-bold">ランク一覧</h4>
      <v-divider class="my-2"></v-divider>
      <div class="" v-for="rank in item.rank_list" :key="rank.id">
        <v-row>
          <v-col cols="3">
            <div style="position:relative;">
            <v-img src="@/assets/stamp/rankicon@2x.png" width="50"></v-img>
            <div class="ranknumber">
              {{ordinal(rank.rank_num)}}<br>Rank
            </div>
            </div>
          </v-col>
          <v-col cols="9">
            <h5 class="subtitle-1 font-weight-bold">{{rank.name}}</h5>
            <p class="fontsize11 mb-1">{{rank.num}}</p>
            <v-chip outlined x-small class="mb-2" style="color:#9D9D9D; border-color: #9D9D9D;" v-if="rank.fuyo_type == 1">コンプリート特典</v-chip>
            <v-chip outlined x-small class="mb-2" style="color:#9D9D9D; border-color: #9D9D9D;" v-else>還元率</v-chip>
            <div v-if="rank.fuyo_type == 1">
              <p class="subtitle-2 ma-0">{{rank.title}}</p>
            </div>
            <div v-else>
              <div class="subtitle-2 ma-0" v-for="(fuyo, i) in rank.fuyos" :key="i">
                <div class="subtitle-2" v-html="fuyo.fuyo_str"></div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-divider class="my-2"></v-divider>
      </div>

      <div class="text-center">
        <v-btn rounded depressed class="caption font-weight-black btn_small mt-5 mb-10 w80" style="background-color:#EFEFEF;" @click="close()">閉じる</v-btn>
      </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['item'],
  data() {
    return {
      rankDialog: false
    };
  },
  methods: {
    open() {
      this.rankDialog = true;
    },
    close() {
      this.rankDialog = false;
    },
    ordinal(n) {
      var result;
      var s1 = +('' + n).slice(-1);
      var s2 = +('' + n).slice(-2);
      if (s2 >= 11 && s2 <= 13) {
        result = n + 'th';
      } else if (s1 === 1) {
        result = n + 'st';
      } else if (s1 === 2) {
        result = n + 'nd';
      } else if (s1 === 3) {
        result = n + 'rd';
      } else {
        result = n + 'th';
      }
      return result;
    }
  }
}
</script>

<style scoped>
.ranknumber {
  position: absolute;
  top:10px;
  left: 0;
  text-align: center;
  width: 50px;
  font-size: 9px;
  z-index: 10;
  line-height: 1;
  font-weight: bold;
}
</style>
